import { Button, Grid } from "@ofrz/ui-package";
import { AutoCompleteOption } from "components/auto-complete/auto-complete.props";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { vendorProductAutoCompleteService } from "services/offerz-service";
import { AppDispatch, ApplicationState } from "store/application-state";
import {
  deleteStagedProduct,
  fetchStagedDealsList,
  pormoteToOfferz,
  saveModifedStagedDeals,
  setPage,
  setPendingDealsModifiedItem,
  setPromotesOffersExpirationDate,
} from "store/slices/staged-deal";
import { getFileUrl } from "utils/mediaUrlFormater";
import translate from "utils/translations";
import { PendingDealsProps } from "./pending-deals.props";
import {
  PedingDetailsRequestHeaders,
  StagedProductModelList,
} from "./pending-deals.util";
import { COLORS } from "libs/colors";
import moment from "moment";
import { on } from "events";
import { getUTCDate } from "utils/date";
import {
  validateExpirationDateState,
  validatModifiedStagedProductState,
} from "./pending-deals.policy";

const PendingDeals = (props: PendingDealsProps) => {
  useEffect(() => {
    props.fetchStagedDealsList();
  }, []);
  const onProductIdChange = async (
    value: string,
    options?: {
      signal?: AbortSignal;
    }
  ): Promise<AutoCompleteOption[]> => {
    if (value === "") {
      return [];
    } else {
      const queryFilter = `?$filter=contains(ProductName, '${value}')`;
      const response = await vendorProductAutoCompleteService(queryFilter);
      if (response && response.result && response.result !== null) {
        const options = response.result.list.map((obj) => {
          console.log(obj.pictureFileId);

          return {
            value: obj.id!,
            label: `${obj.productName} - ${obj.productBrand ?? ""}`,
            imgUrl: getFileUrl(obj.pictureFileId ?? ""),
          };
        });
        return options;
      }
    }
    return [];
  };
  const onSelectItem = (option: AutoCompleteOption, propKey?: string) => {
    const { value, label } = option;
    const nameSplit = propKey!.split("-");
    props.setPendingDealsItem(Number(nameSplit[1]), nameSplit[0], value);
    props.setPendingDealsItem(Number(nameSplit[1]), "productName", label);
  };
  const onTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const nameSplit = name.split("-");
    props.setPendingDealsItem(Number(nameSplit[1]), nameSplit[0], value);
  };
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between py-[25px] text-[30px] font-medium">
        {translate("Pending_Offers_Text", "Pending Offers TODO")}
      </div>
      <div className="flex w-full item-center justify-end my-[25px]">
        <div className="flex-col w-[250px] px-1">
          <input
            type="date"
            id="expirationDate"
            name="expirationDate"
            className="w-full align-middle p-2 border-2 rounded-lg border-[#D0D5DD]"
            onChange={(event) =>
              props.onOfferExpirationDateChange(getUTCDate(event.target.value))
            }
            value={moment(props.expirationDate).format("YYYY-MM-DD")}
          />
          {props.promoteValidationState.expirationsDate && (
            <p className="text-red-500 text-sm mt-1">
              {props.promoteValidationState.expirationsDate}
            </p>
          )}
        </div>
        <div>
          <Button
            className="text-white rounded-lg mx-2"
            onClick={props.onPormoteOffers}
            primary
          >
            {translate("Promote_Offers_Text", "Promote Offers TODO")}
          </Button>
        </div>
      </div>
      <Grid
        columns={PedingDetailsRequestHeaders}
        dataSource={StagedProductModelList(
          props.pendingDealsModifiedList,
          onProductIdChange,
          onSelectItem,
          onTextInputChange,
          props.onDeleteStagedProduct,
          props.updateStagedDealsValidationState
        )}
        currentPage={props.pendingDealsList.pageIndex + 1}
        onPageChange={(newPage: number) => {
          props.onPageChange(newPage - 1);
        }}
        pagination={true}
        pageSize={props.pendingDealsList.pageSize}
        totalCount={props.pendingDealsList.totalCount}
        onSort={(dataIndex: string, order: "desc" | "asc") => {}}
      />
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  promoteValidationState: validateExpirationDateState(state.stageddeal),
  updateStagedDealsValidationState: validatModifiedStagedProductState(
    state.stageddeal
  ),
  pendingDealsList: state.stageddeal.pendingDealsList,
  pendingDealsModifiedList: state.stageddeal.pendingDealsModifiedList,
  expirationDate: state.stageddeal.expirationDate,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setPendingDealsItem: (index: number, name: string, value: any) => {
    dispatch(setPendingDealsModifiedItem({ index, name, value }));
  },
  fetchStagedDealsList: () => {
    dispatch(fetchStagedDealsList());
  },
  onPageChange: (pageNumber: number) => {
    dispatch(saveModifedStagedDeals(pageNumber));    
  },
  onPormoteOffers: () => {
    dispatch(pormoteToOfferz());
  },
  onOfferExpirationDateChange: (expirationDate: Date) => {
    dispatch(setPromotesOffersExpirationDate(expirationDate));
  },
  onDeleteStagedProduct: (id: string) => {
    dispatch(deleteStagedProduct(id));
  },
});

const PerndingDealsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingDeals);

export { PerndingDealsContainer };
