import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "..";
import { addSnack } from "../store/slices/ui";
import { jwtDecode } from "jwt-decode";
import {  setLoginLogic } from "../store/slices/login";
import { refreshToken } from "./offerz-service";
import { AsyncAppThunk } from "../store/application-state";

function getAuthToken(): string | null {
  if (store.getState().login.loggedUser === null) return null;
  const token = store.getState().login.loggedUser!.token;
  return token;
}

function getRefreshToken(): string | null {
  if (store.getState().login.loggedUser === null) return null;
  const token = store.getState().login.loggedUser!.refreshToken;
  return token;
}

class HttpService {
  private client: AxiosInstance;

  constructor(baseURL: any) {
    this.client = axios.create({
      baseURL: baseURL,
      timeout: 100000,
      headers: {
        "Content-Type": "application/json",
      },
    });
    this.configureInterceptors();
  }

  private configureInterceptors() {
    this.client.interceptors.request.use(
      async (config) => {
        var token = getAuthToken();
        if (token === null) return config;
        if (config.url && !config.url.includes("/RefreshToken")) {
          var data = jwtDecode(token);
          if (data && data.exp) {
            const expiration = data.exp * 1000; // Convert milliseconds from seconds
            const currentTime = new Date().getTime();
            if (currentTime > expiration) {
              var rToken = getRefreshToken();
              if (rToken != null) {
                var response = await refreshToken(rToken);
                store.dispatch(setLoginLogic(response));
                if (response) {
                  token = response && response?.result.token;
                }
              }
            } else {
              console.log("Token is valid");
            }
          }
        }

        config.headers.Authorization = `Bearer ${token}`;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async get<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T | null> {
    try {
      const response: AxiosResponse<T> = await this.client.get(url, config);

      return response ? response.data : null;
    } catch (error: any) {
      var response = {};
      var errorMessage = error.meesage;
      if (error && error.response && error.response.data) {
        response = error.response.data;
        errorMessage = error.response.data.errorMessage ?? errorMessage;
      }
      store.dispatch(addSnack(errorMessage));
      return Promise.resolve(response as T);
    }
  }
  async delete<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T | null> {
    try {
      const response: AxiosResponse<T> = await this.client.delete(url, config);
      return response ? response.data : null;
    } catch (error: any) {
      var response = {};
      var errorMessage = error.meesage;
      if (error && error.response && error.response.data) {
        response = error.response.data;
        errorMessage = error.response.data.errorMessage ?? errorMessage;
      }
      store.dispatch(addSnack(errorMessage));
      return Promise.resolve(response as T);
    }
  }
  async post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T | null> {
    try {
      const response: AxiosResponse<T> = await this.client.post(
        url,
        data instanceof FormData ? data : JSON.stringify(data),
        {
          ...config,
          headers:
            data instanceof FormData
              ? { ...config?.headers, "Content-Type": "multipart/form-data" }
              : config?.headers, // Maintain existing headers for plain objects
        }
      );
      return response ? response.data : null;
    } catch (error: any) {
      var response = {};
      var errorMessage = error.meesage;
      if (error && error.response && error.response.data) {
        response = error.response.data;
        errorMessage = error.response.data.errorMessage ?? errorMessage;
      }
      store.dispatch(addSnack(errorMessage));
      return Promise.resolve(response as T);
    }
  }
  async put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T | null> {
    try {
      const response: AxiosResponse<T> = await this.client.put(
        url,
        data instanceof FormData ? data : JSON.stringify(data),
        {
          ...config,
          headers:
            data instanceof FormData
              ? { ...config?.headers, "Content-Type": "multipart/form-data" }
              : config?.headers,
        }
      );
      return response ? response.data : null;
    } catch (error: any) {
      var response = {};
      var errorMessage = error.meesage;
      if (error && error.response && error.response.data) {
        response = error.response.data;
        errorMessage = error.response.data.errorMessage ?? errorMessage;
      }
      store.dispatch(addSnack(errorMessage));
      return Promise.resolve(response as T);
    }
  }
}

const HttpAuthentication = new HttpService(
  process.env.REACT_APP_AUTHENTICATION_BASE_URL
);
const HttpFileManagment = new HttpService(
  process.env.REACT_APP_FILES_MANAGMENT_BASE_URL
);
const HttpStagedProducts = new HttpService(
  process.env.REACT_APP_OFFERZ_MAGAZINE_EXTRACTION_BASE_URL
);
const HttpOfferz = new HttpService(process.env.REACT_APP_OFFERZ_BASE_URL);
export { HttpOfferz, HttpAuthentication,HttpFileManagment,HttpStagedProducts };
