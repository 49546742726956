import { StagedProductModel } from "libs/models/staged-product";
import { ValidationModel } from "libs/models/validate-model";
import { REGEX_PATTERN } from "libs/regex-patterns";
import { StagedDealStore } from "store/slices/staged-deal";
import translate from "utils/translations";

export const validateExpirationDateState = (
  state: StagedDealStore
): ValidationModel<{ expirationsDate: string }> => {
  let expirationDateValidation = {} as ValidationModel<{
    expirationsDate: string;
  }>;
  if (!state.isPromoteStagedDealsValidating) {
    return expirationDateValidation;
  }
  if (state.expirationDate === null || state.expirationDate === "") {
    expirationDateValidation.expirationsDate = translate(
      "Required_Text",
      "This field is required"
    );
  }
  return expirationDateValidation;
};
export const validatModifiedStagedProductState = (
  state: StagedDealStore
): ValidationModel<StagedProductModel>[] => {
  let stagedProductValidationModel: ValidationModel<StagedProductModel>[] =
    Array.from(
      { length: state.pendingDealsModifiedList.length },
      () => ({} as ValidationModel<StagedProductModel>)
    );

  if (!state.isUpdateStagedDealsValidating) {
    return stagedProductValidationModel;
  }

  state.pendingDealsModifiedList.forEach((item, index) => {    
    if ( item.offerName === null || item.offerName === "") {
      stagedProductValidationModel[index].offerName = translate(
        "Required_Text",
        "This field is required"
      );
    } 
    if (
      item.salePrice === null ||
      item.salePrice === "0" ||
      item.salePrice === ""
    ) {
      stagedProductValidationModel[index].salePrice = translate(
        "Required_Text",
        "This field is required"
      );
    } else if (!REGEX_PATTERN.MONEY.test(item.salePrice!)) {
      stagedProductValidationModel[index].salePrice = translate(
        "Accept_Decimal_Text",
        "Accept decimal values only"
      );
    }
  });

  return stagedProductValidationModel;
};
