import { Button, TextInput } from "@ofrz/ui-package";
import React, { useEffect } from "react";
import { EditableLabelProps } from "./editable-label.props";
import translate from "utils/translations";

const EditableLabel = (props: EditableLabelProps) => {
  const [isEditable, setEditable] = React.useState<boolean>(false);
  useEffect(() => {
    setEditable(false);
  }, [props.id]);
  const onChangeEditableState = () => {
    setEditable((prevState) => !prevState);
  };
  return (
    <div className="w-full ">
      {isEditable ? (
        <TextInput
          id={props.id}
          className="w-full"
          title={props.title}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          name={props.name}
          error={props.error}
        />
      ) : (
        <div className="flex w-full justify-between items-center space-x-2">
          <div className="flex flex-col">
            <div className="text-sm font-normal">{props.value}</div>
            {props.error && props.error !== "" && (
              <p className="text-red-500 text-sm mt-1">{props.error}</p>
            )}
          </div>
          <div>
            <Button
              onClick={onChangeEditableState}
              className="text-[#518C7D] font-semibold text-[10px]"
            >
              {translate("Edit_Text", "Edit TODO")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export { EditableLabel };
