const REGEX_PATTERN = {
  ALPHAPET_ONLY: new RegExp("^[a-zA-Z]+$"),
  NUMBERS_ONLY: new RegExp("^\\d+$"),
  ALPHAPET_NUMBERS_ONLY: new RegExp(
    "^[A-Za-z0-9\u0600-\u06FF\u0660-\u0669s+]+$"
  ),
  MONEY: new RegExp("^[0-9]*.?[0-9]+$"),
  WEBSITE: new RegExp("^www\\.[a-zA-Z0-9\\-\\.]+\\.[a-zA-Z]{2,}$", "i"),
};

export { REGEX_PATTERN };
