import { push } from "@lagunovsky/redux-react-router";
import { useEffect } from "react";
import { connect } from "react-redux";
import { LoginImg } from "../../static/images/svgs/login-img";
import { OfferzLogo } from "../../static/images/svgs/offerz-logo";
import { AppDispatch, ApplicationState } from "../../store";
import translate from "../../utils/translations";
import LoginFormContainer from "./login-form-container";
import { SignInProps } from "./signin.props";
import { ROUTES } from "../../libs";
import { isSystemOrVendorAdmin } from "../../store/slices/login";
import { CrosspayLogo } from "static/images/svgs/crosspay-logo";
import { COLORS } from "libs/colors";

const SignIn = (props: SignInProps) => {
  useEffect(() => {
    if (!!props.loggedInUserRole) {
      props.isSystemOrVendorAdmin();
    }
  }, [props.loggedInUserRole]);
  const goTo = (route: string) => {
    props.goTo(route);
  };
  const goToGiftCardLoginLink = ()=>{
    window.location.href =`${process.env.PUBLIC_URL}/crosspay-dashboard-ui/login`
  }
  return (
    <div className="flex w-full h-screen">
      <div className="flex flex-col lg:w-1/2 px-20  ">
        <div className="w-[10rem] h-[10rem] mt-[5rem]">
          <CrosspayLogo width="auto" height="auto" />
        </div>
        <div className="flex flex-wrap w-full rounded-lg border-2 border-gray-300 mt-2">
          <div
            className={`rounded-s-lg w-1/2 text-center py-1 bg-[#518c7dd9] ${COLORS.TEXT_WHITE} cursor-pointer`}
            onClick={(e) => goTo("login")}
          >
            {translate("Login_Offerz_User","Login as Offerz User")}
          </div>
          
          <div
            className={`rounded-e-lg w-1/2 text-center py-1  ${COLORS.TEXT_PRIMARY} cursor-pointer`}
            onClick={goToGiftCardLoginLink}
          >
            {translate("Login_Giftcard_User","Login as Gift Card User")}

          </div>
        </div>
        <h2 className="text-5xl font-semibold font-sans my-5">
          {translate("Login_Text", "Login")}
        </h2>

        <div className="w-full flex flex-col mb-auto ">
          <LoginFormContainer />
        </div>
      </div>
      <div className="lg:w-1/2 h-screen px-10 flex justify-center">
        <LoginImg />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  loggedInUserRole: state.login.userRole,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  goTo: (route: string) => {
    dispatch(push(route));
  },
  isSystemOrVendorAdmin: () => {
    dispatch(isSystemOrVendorAdmin());
  },
});

const SignInContainer = connect(mapStateToProps, mapDispatchToProps)(SignIn);

export { SignInContainer };
