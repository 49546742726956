import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Filter } from "libs/models/filter";
import { PaginatedList } from "libs/models/paginated-list";
import { StagedProductModel } from "libs/models/staged-product";
import {
  deleteStagedProductService,
  getStagedDealsList,
  pormoteToOfferzService,
  updateStagedDealsList,
} from "services/offerz-service";
import { AsyncAppThunk } from "store/application-state";
import { getFilterQuery } from "utils/filter";
import { showLoaderWhile } from "./ui";
import {
  validateExpirationDateState,
  validatModifiedStagedProductState,
} from "pages/PendingDeals/pending-deals.policy";

export interface StagedDealStore {
  expirationDate: string | null;
  isPromoteStagedDealsValidating: boolean;
  isUpdateStagedDealsValidating: boolean;
  pendingDealsList: PaginatedList<StagedProductModel>;
  pendingDealsModifiedList: StagedProductModel[];
  filter: Filter;
}

const initialState: StagedDealStore = {
  expirationDate: null,
  isPromoteStagedDealsValidating: false,
  isUpdateStagedDealsValidating: false,
  filter: {},
  pendingDealsList: {
    list: [],
    pageIndex: 0,
    pageSize: 5,
    totalCount: 0,
  },
  pendingDealsModifiedList: [],
};

const slice = createSlice({
  name: "staged-deals",
  initialState,
  reducers: {
    setIsPromoteStagedDealsValidating: (
      state: StagedDealStore,
      action: PayloadAction<boolean>
    ) => {
      state.isPromoteStagedDealsValidating = action.payload;
    },
    setIsupdateStagedDealsValidating: (
      state: StagedDealStore,
      action: PayloadAction<boolean>
    ) => {
      state.isUpdateStagedDealsValidating = action.payload;
    },
    setPendingDealList: (
      state: StagedDealStore,
      action: PayloadAction<PaginatedList<StagedProductModel>>
    ) => {
      state.pendingDealsList = action.payload;
      state.pendingDealsModifiedList = action.payload.list;
    },
    setPromotesOffersExpirationDate: (
      state: StagedDealStore,
      action: PayloadAction<Date>
    ) => {
      state.expirationDate = action.payload.toISOString();
    },
    setPendingDealsModifiedItem: (
      state: StagedDealStore,
      action: PayloadAction<{ index: number; name: string; value: any }>
    ) => {
      const { index, name, value } = action.payload;
      state.pendingDealsModifiedList[index] = {
        ...state.pendingDealsModifiedList[index],
        [name]: value,
      };
    },
    setPage: (state: StagedDealStore, action: PayloadAction<number>) => {
      {
        state.pendingDealsList.pageIndex = action.payload;
        state.isUpdateStagedDealsValidating = false;
      }
    },
  },
});

export const {
  setIsPromoteStagedDealsValidating,
  setIsupdateStagedDealsValidating,
  setPage,
  setPendingDealList,
  setPendingDealsModifiedItem,
  setPromotesOffersExpirationDate,
} = slice.actions;
export const reducer = slice.reducer;

export const fetchStagedDealsList =
  (): AsyncAppThunk => async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        const dealsListResponse = await getStagedDealsList(
          getFilterQuery(
            store().stageddeal.filter,
            store().stageddeal.pendingDealsList.pageIndex *
              store().stageddeal.pendingDealsList.pageSize,
            store().stageddeal.pendingDealsList.pageSize
          )
        );
        if (dealsListResponse && dealsListResponse?.result !== null) {
          dispatch(setPendingDealList(dealsListResponse!.result));
        }
      })
    );
  };
export const saveModifedStagedDeals =
  (page: number): AsyncAppThunk =>
  async (dispatch, store) => {
    dispatch(setIsupdateStagedDealsValidating(true));

    const isAllEmpty = validatModifiedStagedProductState(
      store().stageddeal
    ).every((item) => Object.keys(item).length === 0);
    if (!isAllEmpty) return;
    const pendingDealsList = store().stageddeal.pendingDealsList.list;
    const pendingDealsModifiedList =
      store().stageddeal.pendingDealsModifiedList;
    const modifiedItemMap = new Map(
      pendingDealsModifiedList.map((item) => [item.id, item])
    );

    const modfiedStagedProductList = pendingDealsList.reduce<
      StagedProductModel[]
    >((acc, item) => {
      const modifiedItem = modifiedItemMap.get(item.id);

      if (
        !modifiedItem ||
        (modifiedItem.productId === item.productId &&
          modifiedItem.offerName === item.offerName &&
          modifiedItem.salePrice === item.salePrice)
      ) {
        return acc;
      }

      acc.push(modifiedItem);
      return acc;
    }, []);
    if (modfiedStagedProductList.length === 0) {
      {
        dispatch(setPage(page));
        dispatch(fetchStagedDealsList());
        return;
      }
    }
    dispatch(
      showLoaderWhile(async () => {
        const response = await updateStagedDealsList(modfiedStagedProductList);
        if (response && response?.result !== null) {
          dispatch(setPage(page));
          dispatch(fetchStagedDealsList());
        }
      })
    );
  };
export const pormoteToOfferz = (): AsyncAppThunk => async (dispatch, store) => {
  const expirationDate = store().stageddeal.expirationDate;
  dispatch(setIsPromoteStagedDealsValidating(true));
  if (
    Object.keys(validateExpirationDateState(store().stageddeal)).length == 0
  ) {
    dispatch(
      showLoaderWhile(async () => {
        const response = await pormoteToOfferzService(expirationDate!);
        if (response && response?.result !== null) {
          dispatch(setIsPromoteStagedDealsValidating(false));
          dispatch(fetchStagedDealsList());
        }
      })
    );
  }
};
export const deleteStagedProduct =
  (stagedProductId: string): AsyncAppThunk =>
  async (dispatch, store) => {
    dispatch(
      showLoaderWhile(async () => {
        const response = await deleteStagedProductService(stagedProductId);
        if (response && response?.result !== null) {
          dispatch(fetchStagedDealsList());
        }
      })
    );
  };
