import { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import "./App.css";
import { AppProps } from "./App.props";
import { LoaderContainer } from "./components/loader";
import ProtectedRoutes from "./components/protected-route/protected-routes";
import { ROUTES } from "./libs";
import { CookiesKeys, UserCulture } from "./libs/enums";
import { SignInContainer } from "./pages/Signin";
import SubmitedPage from "./pages/SubmitedPage";
import { VendorRegistrationStepperContainer } from "./pages/VendorRegistrationStepper";
import { AppDispatch, ApplicationState } from "./store";
import { setCookie } from "./utils/cookies";
import { RolesTypes } from "./libs/models/login";
import { VendorAdminApplayoutContainer } from "./components/vendor-admin-app-layout";
import { SystemAdminApplayout } from "./components/system-admin-app-layout";
import { SnackBar } from "./components/snack-bar";
import { removeSnack } from "./store/slices/ui";
import { SYSTEM_ADMIN, VENDOR_ROUTES } from "./libs/routes";
import { MagazineContainer } from "./pages/Magazine";
import { ModalPopUpContainer } from "./components/modal/modal-popup.container";
import VendorsPage from "./pages/SystemAdmin/Vendors/vendors-page";
import VendorRequestPage from "./pages/SystemAdmin/vendor-request/vendor-request-page";
import TermsConditionsPage from "./pages/SystemAdmin/terms-conditions/terms-conditions-page";
import { ChangePasswordDialog } from "./components/change-password-dialog/change-password-dialog";
import { ChangePasswordModel } from "./libs/models/change-password";
import { onPasswordChangeSubmit } from "./store/slices/login";
import VendorDetailsPage from "./pages/SystemAdmin/vendor-details/vendor-details-page";
import { CategoriesContainer } from "./pages/Categories";
import { DealsContainer } from "pages/Deals/deals.container";
import { VendorProductContainer } from "./pages/VendorProduct";
import { AdminProductContainer } from "./pages/AdminProduct";
import { LandingPage } from "pages/LandingPage";
import { ForgetPasswordContainer } from "pages/ForgetPassword/forget-password.container";
import { PerndingDealsContainer } from "pages/PendingDeals";

const App = (props: AppProps) => {
  useEffect(() => {
    setCookie(CookiesKeys.userCulture, UserCulture.English);
  }, [props.userCulture]);

  return (
    <div>
      <LoaderContainer />
      <ModalPopUpContainer />
      <Routes>
        <Route path={ROUTES.BASE} element={<LandingPage />} />
        <Route path={ROUTES.LOGIN} element={<SignInContainer />} />
        <Route
          path={ROUTES.FORGET_PASSWORD}
          element={<ForgetPasswordContainer />}
        />

        <Route
          path={ROUTES.SIGN_UP_ADMIN}
          element={<div>System sign up page</div>}
        />

        <Route
          path={ROUTES.SIGN_UP_VENDOR}
          element={<VendorRegistrationStepperContainer />}
        />

        {props.loggedInUserRole === RolesTypes.VendorAdmin ? (
          <>
            <Route
              path={VENDOR_ROUTES.REGISTRATION_STATUS}
              element={<SubmitedPage />}
            />
            <Route
              path={VENDOR_ROUTES.BASE}
              element={
                <ProtectedRoutes>
                  <VendorAdminApplayoutContainer />
                </ProtectedRoutes>
              }
            >
              <Route
                path={VENDOR_ROUTES.DASHBOARD}
                element={<div>Dashboard</div>}
              />
              <Route path={VENDOR_ROUTES.OFFERZ} element={<DealsContainer />} />
              <Route
                path={VENDOR_ROUTES.PENDING_OFFERZ}
                element={<PerndingDealsContainer />}
              />
              <Route
                path={VENDOR_ROUTES.MAGAZINE}
                element={<MagazineContainer />}
              />
              <Route
                path={VENDOR_ROUTES.CATEGORIES}
                element={<CategoriesContainer />}
              />
              <Route
                path={VENDOR_ROUTES.PRODUCTS}
                element={<VendorProductContainer />}
              />
              <Route
                path={VENDOR_ROUTES.REPORTS}
                element={<div>REPORTS</div>}
              />
              <Route path={VENDOR_ROUTES.USERS} element={<div>USERS</div>} />
            </Route>
          </>
        ) : (
          <Route>
            <Route
              path={ROUTES.BASE}
              element={
                <ProtectedRoutes>
                  <SystemAdminApplayout />
                </ProtectedRoutes>
              }
            >
              <Route
                path={SYSTEM_ADMIN.DASHBOARD}
                element={<div>Admin Dashboard</div>}
              />
              <Route path={SYSTEM_ADMIN.VENDORS} element={<VendorsPage />} />
              <Route
                path={SYSTEM_ADMIN.VENDOR_REQUEST}
                element={<VendorRequestPage />}
              />
              <Route
                path={SYSTEM_ADMIN.VENDOR_DETAILS}
                element={<VendorDetailsPage />}
              />
              <Route
                path={SYSTEM_ADMIN.TERMSANDCONITIONS}
                element={<TermsConditionsPage />}
              />
              <Route
                path={SYSTEM_ADMIN.CATEGORIES}
                element={<CategoriesContainer />}
              />
              <Route
                path={SYSTEM_ADMIN.PRODUCTS}
                element={<AdminProductContainer />}
              />
            </Route>
          </Route>
        )}
        <Route path="*" element={<Navigate to={ROUTES.LOGIN} replace />} />
      </Routes>
      <SnackBar snack={props.snack} onRemoveSnack={props.onRemoveSnack} />
      {props.isPasswordExpired && (
        <ChangePasswordDialog
          onConfirmPasswordChange={props.onConfirmPasswordChange}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onRemoveSnack: () => {
    dispatch(removeSnack());
  },
  onConfirmPasswordChange: (passwordModel: ChangePasswordModel) => {
    dispatch(onPasswordChangeSubmit(passwordModel));
  },
});

const mapStateToProps = (state: ApplicationState) => {
  return {
    loggedInUserRole: state.login.userRole,
    userCulture: state.login.userCulture,
    isPasswordExpired: state.login.isPasswordExpired,
    snack:
      Object.keys(state.ui.snacks).length > 0
        ? state.ui.snacks[Object.keys(state.ui.snacks)[0]]
        : undefined,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
