import { AutoComplete, Button, TextInput } from "@ofrz/ui-package";
import { AutoCompleteOption } from "components/auto-complete/auto-complete.props";
import { EditableLabel } from "components/EditableLabel";
import { COLORS } from "libs/colors";
import { StagedProductModel } from "libs/models/staged-product";
import { ValidationModel } from "libs/models/validate-model";
import { DeleteIcon } from "static/images/svgs/delete-icon-svg";
import translate from "utils/translations";

export const PedingDetailsRequestHeaders = [
  {
    title: translate("Offer_Name_Text", "Offer Name TODO"),
    dataIndex: "offerName",
    key: "offerName",
    isSortable: false,
    className: "!bg-black",
  },
  {
    title: translate("Offer_Price_Text", "Offer Price TODO"),
    dataIndex: "offerPrice",
    key: "offerPrice",
    isSortable: false,
  },
  {
    title: (
      <div>
        {translate("Included_Product_Text", "Included Product(s) TODO")}
      </div>
    ),
    dataIndex: "productId",
    key: "productId",
    isSortable: false,
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    isSortable: false,
  },
];
export const StagedProductModelList = (
  productModel: StagedProductModel[],
  onProductValueChange: (
    value: string,
    options?: any
  ) => Promise<AutoCompleteOption[]>,
  onSelectItem: (option: AutoCompleteOption, propKey?: string) => void,
  onTextInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onDeleteStagedProduct: (id: string) => void,
  fieldValidationState: ValidationModel<StagedProductModel>[]
) => {
  const data = [];
  for (var i = 0; i < productModel.length; i++) {
    const request = productModel[i];
    const row: Record<string, any> = {
      id: request.id,
      offerName: (
        <EditableLabel
          id={request.id!}
          title=""
          value={request.offerName}
          placeholder={translate("Enter_Offer_Name_Here", "Please enter offer name here TODO")}
          onChange={onTextInputChange}
          name={`offerName-${i}`}
          error={ fieldValidationState[i].offerName
              ? fieldValidationState[i].offerName
              : ""
          }
        />
      ),
      offerPrice: (
        <div>
          <EditableLabel
            id={request.id!}
            title=""
            value={request.salePrice}
            placeholder={translate("Enter_Offer_Price_Here", "Please enter offer price here TODO")}
            onChange={(e) => onTextInputChange(e)}
            name={`salePrice-${i}`}
            error={fieldValidationState[i].salePrice
                ? fieldValidationState[i].salePrice
                : ""
            }
          />
        </div>
      ),
      productId: (
        <div>
          <AutoComplete
            id="test"
            title=""
            placeholder="Product Name"
            onChange={onProductValueChange}
            onSelect={onSelectItem}
            name={`productId-${i}`}
            defaultValue={request.productName}
          />
        </div>
      ),
      actions: (
        <div className="text-center">
          <Button
            className={`text-[#DB3737] text-[14px] font-semibold !p-0 m-2`}
            onClick={(e) => onDeleteStagedProduct(request.id!)}
          >
            {translate("Delete_Text", "Delete TODO")}
          </Button>
        </div>
      ),
    };
    data.push(row);
  }
  return data;
};
