const ROUTES = {
  BASE: "/",
  LOGIN: "/login",
  FORGET_PASSWORD: "/forget-password",
  SIGN_UP_VENDOR: "/signup/vendor",
  SIGN_UP_ADMIN: "/signup/admin",

};
const VENDOR_ROUTES = {
  BASE: "/vendor",
  REGISTRATION_STATUS: "/vendor/Registration-Status",

  DASHBOARD: "/vendor/dashboard",
  OFFERZ: "/vendor/offers",
  MAGAZINE: "/vendor/magazine",
  CATEGORIES: "/vendor/categories",
  REPORTS: "/vendor/reports",
  USERS: "/vendor/users",
  PRODUCTS: "/vendor/products",
  PENDING_OFFERZ: "/vendor/pending-offerz",
}
const SYSTEM_ADMIN = {
  BASE: "/admin",
  DASHBOARD: "/admin/dashboard",
  VENDORS: "/admin/vendors",
  VENDOR_REQUEST: "/admin/vendors/requests/:id",
  VENDOR_DETAILS: "/admin/vendors/details/:id",
  TERMSANDCONITIONS: "/admin/termsconditions",
  CATEGORIES: "/admin/categories",
  PRODUCTS: "/admin/products"
};
export { ROUTES, VENDOR_ROUTES, SYSTEM_ADMIN };
