import {
  FILE_MANAGMENT_END_POINTS,
  MAGAZINE_PRODUCT_EXTRACTION_ENDPOINTS,
  OFFERZ_END_POINTS,
  USER_MANAGMENT_END_POINTS,
} from "../libs/endpoints";
import { CategoryModel } from "../libs/models/category";
import { ChangePasswordModel } from "../libs/models/change-password";
import { Response } from "../libs/models/generic-response";
import {
  LoginModel,
  LoginResponse,
  LoginTermsConditionsResponse,
  SystemsId,
} from "../libs/models/login";
import { MagazineModel, MagazinStartProcessingRequest } from "../libs/models/magazine";
import { DealsModel } from "../libs/models/deals";
import { PaginatedList } from "../libs/models/paginated-list";
import { ProductModel } from "../libs/models/product";
import { SignupModel } from "../libs/models/signup";
import {
  TermsConditionsCreate,
  TermsConditionsModel,
  TermsConditionsType,
  UnconfirmedEntityTerms,
  VendorDetailsResponse,
  VendorsDetailsResponse,
  VendorsRequestsResponse,
} from "../libs/models/system-admin";
import {
  RequestVendorProductPricing,
  VendorProductModel,
} from "../libs/models/vendor-product";
import { VendorRegisterRequestModel } from "../libs/models/vendor-register-request";
import {
  HttpAuthentication,
  HttpFileManagment,
  HttpOfferz,
  HttpStagedProducts,
} from "./https";
import { BrandModel } from "libs/models/brand";
import {
  ForgetPasswordChange,
  ForgetPasswordRequestOtp,
  ForgetPasswordVerifyOtp,
} from "libs/models/forget-password";
import { StagedDealStore } from "store/slices/staged-deal";
import { StagedProductModel } from "libs/models/staged-product";

const loginService = async (
  model: LoginModel
): Promise<Response<LoginResponse> | null> => {
  return await HttpAuthentication.post(
    USER_MANAGMENT_END_POINTS.LOGIN_ADMIN,
    model
  );
};

const refreshToken = async (
  refreshToken: string
): Promise<Response<LoginResponse> | null> => {
  return await HttpAuthentication.post(
    USER_MANAGMENT_END_POINTS.REFRESH_TOKEN,
    {
      refreshToken: refreshToken,
      systemId: SystemsId.OfferzSystemId,
    }
  );
};

const changePassword = async (
  passwordModel: ChangePasswordModel
): Promise<Response<boolean> | null> => {
  return await HttpAuthentication.put(
    USER_MANAGMENT_END_POINTS.Change_Password,
    passwordModel
  );
};

const signupService = async (
  model: SignupModel
): Promise<Response<LoginResponse> | null> => {
  return await HttpAuthentication.post(
    USER_MANAGMENT_END_POINTS.VENDOR_ADMIN_REGISTRATION,
    model
  );
};

const requestVerificationService = async (
  email: string
): Promise<Response<boolean> | null> => {
  return await HttpAuthentication.post(
    USER_MANAGMENT_END_POINTS.VENDOR_RESEND_VERIFICATION,
    email
  );
};
const vendorRegisterService = async (
  model: VendorRegisterRequestModel
): Promise<Response<boolean> | null> => {
  return await HttpOfferz.post(
    OFFERZ_END_POINTS.VENDOR_REGISTER_REQUEST,
    model
  );
};
const userIsRegistered = async (
  userName: string
): Promise<Response<boolean> | null> => {
  return await HttpOfferz.get(OFFERZ_END_POINTS.USER_IS_MEMBER, {
    params: { userName: userName },
  });
};

const getTermsConditions =
  async (): Promise<Response<LoginTermsConditionsResponse> | null> => {
    return await HttpOfferz.get(OFFERZ_END_POINTS.VENDOR_TERMS_CONDITIONS);
  };

const createTermsConditions = async (
  model: TermsConditionsCreate
): Promise<Response<string> | null> => {
  return await HttpOfferz.post(
    OFFERZ_END_POINTS.CREATE_TERMS_CONDITIONS,
    model
  );
};

const confirmTermsConditions = async (
  id: string
): Promise<Response<string> | null> => {
  return await HttpOfferz.post(
    OFFERZ_END_POINTS.VENDOR_TERMS_CONDITIONS_CONFIRM + `${id}`
  );
};

const getTermsConditionsList = async (
  type: TermsConditionsType
): Promise<Response<TermsConditionsModel[]> | null> => {
  return await HttpOfferz.get(
    `${OFFERZ_END_POINTS.TERMS_CONDITIONS_LIST}${type}`
  );
};

const getTermsConditionsUnconfirmedList = async (
  type: TermsConditionsType
): Promise<Response<UnconfirmedEntityTerms[]> | null> => {
  return await HttpOfferz.get(
    `${OFFERZ_END_POINTS.TERMS_CONDITIONS_UNCONFIRMED_LIST}${type}`
  );
};

const uploadFile = async (
  formData: FormData
): Promise<Response<string> | null> => {
  return await HttpFileManagment.post(
    FILE_MANAGMENT_END_POINTS.UPLOAD_FILE,
    formData
  );
};
const getMagazineService = async (
  queryOptions: string
): Promise<Response<PaginatedList<MagazineModel>> | null> => {
  return await HttpOfferz.get(
    `${OFFERZ_END_POINTS.MAGAZINE_VENDOR}${queryOptions}`
  );
};
const deleteMagazineService = async (
  id: string
): Promise<Response<boolean> | null> => {
  return await HttpOfferz.delete(`${OFFERZ_END_POINTS.MAGAZINE}/${id}`);
};
const editMagazineService = async (
  model: MagazineModel
): Promise<Response<boolean> | null> => {
  return await HttpOfferz.put(OFFERZ_END_POINTS.MAGAZINE, model);
};
const addMagazineService = async (
  model: MagazineModel
): Promise<Response<string> | null> => {
  return await HttpOfferz.post(OFFERZ_END_POINTS.MAGAZINE, model);
};

const GetVendorsRequests = async (
  queryOptions: string | null
): Promise<Response<PaginatedList<VendorsRequestsResponse>> | null> => {
  return await HttpOfferz.get(
    `${OFFERZ_END_POINTS.VENDORS_REQUESTS_LIST}${queryOptions ?? ""}`
  );
};

const GetVendorsList = async (
  queryOptions: string
): Promise<Response<PaginatedList<VendorsDetailsResponse>> | null> => {
  return await HttpOfferz.get(
    `${OFFERZ_END_POINTS.VENDORS_LIST}${queryOptions}`
  );
};

const GetVendorDetails = async (
  id: string
): Promise<Response<VendorDetailsResponse> | null> => {
  return await HttpOfferz.get(`${OFFERZ_END_POINTS.VENDOR_DETAILS}${id}`);
};

const approveVendorRequest = async (
  requestId: string
): Promise<Response<string> | null> => {
  return await HttpOfferz.get(
    OFFERZ_END_POINTS.APPROVE_VENDOR_REQUEST + `${requestId}`
  );
};

const suspendVendor = async (
  vendorId: string
): Promise<Response<string> | null> => {
  return await HttpOfferz.put(OFFERZ_END_POINTS.SUSPEND_VENDOR + `${vendorId}`);
};

const activateVendor = async (
  vendorId: string
): Promise<Response<string> | null> => {
  return await HttpOfferz.put(
    OFFERZ_END_POINTS.ACTIVATE_VENDOR + `${vendorId}`
  );
};

const rejectVendorRequest = async (
  requestId: string
): Promise<Response<string> | null> => {
  return await HttpOfferz.get(
    OFFERZ_END_POINTS.REJECT_VENDOR_REQUEST + `${requestId}`
  );
};

const getCategoriesListService = async (
  queryOptions: string | null
): Promise<Response<PaginatedList<CategoryModel>> | null> => {
  return await HttpOfferz.get(
    `${OFFERZ_END_POINTS.CATEGORY}${queryOptions ?? ""}`
  );
};

const deleteCategoryService = async (
  id: string
): Promise<Response<boolean> | null> => {
  return await HttpOfferz.delete(`${OFFERZ_END_POINTS.CATEGORY}/${id}`);
};
const editCategoryService = async (
  model: CategoryModel
): Promise<Response<boolean> | null> => {
  return await HttpOfferz.put(OFFERZ_END_POINTS.CATEGORY, model);
};

const addCategoryService = async (
  model: CategoryModel
): Promise<Response<string> | null> => {
  return await HttpOfferz.post(OFFERZ_END_POINTS.CATEGORY, model);
};

const getDealsList = async (
  queryOptions: string
): Promise<Response<PaginatedList<DealsModel>> | null> => {
  return await HttpOfferz.get(`${OFFERZ_END_POINTS.DEALS}${queryOptions}`);
};

const addOrUpdateDealService = async (
  model: DealsModel
): Promise<Response<boolean> | null> => {
  return await HttpOfferz.post(OFFERZ_END_POINTS.DEALS, model);
};

const addVendorProductService = async (
  model: RequestVendorProductPricing
): Promise<Response<string> | null> => {
  return await HttpOfferz.post(OFFERZ_END_POINTS.VENDOR_PRODUCT, model);
};
const productAutoCompleteService = async (
  searchText: string,
  options?: {
    signal?: AbortSignal;
  }
): Promise<Response<ProductModel[]> | null> => {
  return await HttpOfferz.get(
    `${OFFERZ_END_POINTS.PRODUCT_AUTO_COMPLETE}/${searchText}`,
    { signal: options?.signal }
  );
};
const getProductListService = async (
  queryOptions: string
): Promise<Response<PaginatedList<ProductModel>> | null> => {
  return await HttpOfferz.get(`${OFFERZ_END_POINTS.PRODUCT}${queryOptions}`);
};
const addProductService = async (
  model: ProductModel
): Promise<Response<string> | null> => {
  return await HttpOfferz.post(OFFERZ_END_POINTS.PRODUCT, model);
};
const editProductService = async (
  model: ProductModel
): Promise<Response<string> | null> => {
  return await HttpOfferz.put(OFFERZ_END_POINTS.PRODUCT, model);
};
const deleteProductService = async (
  id: string
): Promise<Response<boolean> | null> => {
  return await HttpOfferz.delete(`${OFFERZ_END_POINTS.PRODUCT}/${id}`);
};
const getBrandsListService = async (
  queryOptions?: string
): Promise<Response<PaginatedList<BrandModel>> | null> => {
  return await HttpOfferz.get(
    `${OFFERZ_END_POINTS.Brand}${queryOptions ?? ""}`
  );
};
const getVendorProductPriceListService = async (
  queryOptions?: string
): Promise<Response<PaginatedList<VendorProductModel>> | null> => {
  return await HttpOfferz.get(
    `${OFFERZ_END_POINTS.VENDOR_PRODUCT}${queryOptions ?? ""}`
  );
};
const deleteVendorProductPriceService = async (
  id: string
): Promise<Response<boolean> | null> => {
  return await HttpOfferz.delete(`${OFFERZ_END_POINTS.VENDOR_PRODUCT}/${id}`);
};
const editVendorProductPriceService = async (
  model: RequestVendorProductPricing
): Promise<Response<string> | null> => {
  return await HttpOfferz.put(OFFERZ_END_POINTS.VENDOR_PRODUCT, model);
};

const vendorProductAutoCompleteService = async (
  querySearch: string
): Promise<Response<PaginatedList<VendorProductModel>> | null> => {
  return await HttpOfferz.get(
    `${OFFERZ_END_POINTS.VENDOR_PRODUCT}${querySearch}`
  );
};

const requestForgetPasswordOtpService = async (
  model: ForgetPasswordRequestOtp
): Promise<Response<boolean> | null> => {
  return await HttpAuthentication.post(
    USER_MANAGMENT_END_POINTS.FORGET_PASSWORD_REQUEST_OTP,
    model
  );
};
const verifyForgetPasswordOtpService = async (
  model: ForgetPasswordVerifyOtp
): Promise<Response<boolean> | null> => {
  return await HttpAuthentication.post(
    USER_MANAGMENT_END_POINTS.FORGET_PASSWORD_VERIFY_OTP,
    model
  );
};
const forgetPasswordChangePasswordService = async (
  model: ForgetPasswordChange
): Promise<Response<boolean> | null> => {
  return await HttpAuthentication.post(
    USER_MANAGMENT_END_POINTS.FORGET_PASSWORD_CHANGE_PASSWORD,
    model
  );
};
const getStagedDealsList = async (
  queryOptions?: string
): Promise<Response<PaginatedList<StagedProductModel>> | null> => {
  return await HttpStagedProducts.get(
    `${MAGAZINE_PRODUCT_EXTRACTION_ENDPOINTS.STAGED_PRODUCT}${
      queryOptions ?? ""
    }`
  );
};
const updateStagedDealsList = async (
  model: StagedProductModel[]
): Promise<Response<PaginatedList<StagedProductModel>> | null> => {
  return await HttpStagedProducts.put(
    MAGAZINE_PRODUCT_EXTRACTION_ENDPOINTS.STAGED_PRODUCT,
    model
  );
};
const pormoteToOfferzService = async (expirationDate?: string): Promise<Response<
  PaginatedList<StagedProductModel>
> | null> => {
  return await HttpStagedProducts.post(
    MAGAZINE_PRODUCT_EXTRACTION_ENDPOINTS.PORMOT_OFFERZ,
    { expirationDate: expirationDate }
  );
};
const deleteStagedProductService = async (
  id: string
): Promise<Response<boolean> | null> => {
  return await HttpStagedProducts.delete(`${MAGAZINE_PRODUCT_EXTRACTION_ENDPOINTS.STAGED_PRODUCT}/${id}`);
};
const startMagazineProcessingService = async (
  request: MagazinStartProcessingRequest
): Promise<Response<boolean> | null> => {
  return await HttpStagedProducts.post(MAGAZINE_PRODUCT_EXTRACTION_ENDPOINTS.MAGAZONE_PROCESSING,request);
};
export {
  activateVendor,
  addMagazineService,
  addProductService,
  addVendorProductService,
  approveVendorRequest,
  changePassword,
  confirmTermsConditions,
  createTermsConditions,
  deleteCategoryService,
  deleteMagazineService,
  editCategoryService,
  addCategoryService,
  getDealsList,
  addOrUpdateDealService,
  editMagazineService,
  getBrandsListService,
  getCategoriesListService,
  getMagazineService,
  getProductListService,
  getTermsConditions,
  getTermsConditionsList,
  getTermsConditionsUnconfirmedList,
  GetVendorDetails,
  GetVendorsList,
  GetVendorsRequests,
  loginService,
  productAutoCompleteService,
  refreshToken,
  rejectVendorRequest,
  requestVerificationService,
  signupService,
  suspendVendor,
  uploadFile,
  userIsRegistered,
  vendorRegisterService,
  editProductService,
  deleteProductService,
  getVendorProductPriceListService,
  deleteVendorProductPriceService,
  editVendorProductPriceService,
  vendorProductAutoCompleteService,
  requestForgetPasswordOtpService,
  verifyForgetPasswordOtpService,
  forgetPasswordChangePasswordService,
  getStagedDealsList,
  updateStagedDealsList,
  pormoteToOfferzService,
  deleteStagedProductService,
  startMagazineProcessingService
};
